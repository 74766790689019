import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SecretTableItem from "@/entity/system/SecretTableItem";
import UserAPI from "@/api/system/user1";
import API from "@/api/system/secret";
import SelectLevel from "@/modules/select-level/select-level.vue";

@Options({
    name: "app-system-master-secret",
    components: {
        "app-select-level": SelectLevel,
    },
})
export default class Secret extends BaseTableMixins<SecretTableItem> implements Partial<BaseTableInterface<SecretTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new SecretTableItem();
        this.editModalItem = new SecretTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            userCode: {
                type: "eq",
                name: "user_code",
                title: "所属用户",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.userCodeLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //UserAPI
    get getUserApi(): any {
        return UserAPI;
    }

    // //列表回调
    // public listCallback(items: Array<SecretTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new SecretTableItem();
        this.editModalItem = new SecretTableItem();
        this.editModalItem.secretStatus = 1;
        this.editModalTitle = "添加密钥";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.accessKey)) return "访问密钥不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.secretKey)) return "秘密密钥不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.userCode)) return "用户代码不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.secretStatus)) return "密钥状态不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改密钥 (" + this.editModalItem.accessKey + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.secretId)) return "密钥ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除密钥",
            message: "密钥: " + this.editModalItem.accessKey + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.secretId)) return "密钥ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "密钥状态",
            message: "密钥: " + this.editModalItem.accessKey + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.secretStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.secretStatus = this.editModalItem.secretStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.secretId)) return "密钥ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //userCode搜索字段
    private userCodeLevelSearchField: any = [
        { field: "user_code", name: "工号" },
        { field: "real_name", name: "姓名" },
    ];
    //userCode层级选择器打开
    public userCodeLevelModalOpen() {
        (this.$refs.userCodeLevel as any).openModal();
    }
    //userCode层级选择器提交
    public async userCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().userCode : item;
        this.tableRequestItem.lockItem.userCode.value = curItem.value;
        this.tableRequestItem.lockItem.userCode.title = curItem.title;
        this.tableRequestItem.lockItem.userCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get userCodeSelectApi() {
        return {
            api: (query: any) => UserAPI.idSelect(query),
            parent: "org_code",
            status: "user_status",
            name: "real_name",
            id: "user_code",
            cid: this.editModalItem.userCode,
        };
    }
}
